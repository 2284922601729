@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #0d192c;
  color: white;
  font-family: 'Canva Sans', sans-serif;
  overflow: hidden;
}

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s 1s,
    opacity 1s linear;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 3px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-6px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

:root {
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555;
}
